@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
//colors
$primary-color: #1358c0;
$dark-primary: #042d47;
$secondary-color: #3e3e3e;
$light-color: #ffffff;

$success-color: #4caf50;
$danger-color: #f44336;
$info-color: #2196f3;
$warning-color: #ff9800;

$text-color: #2e2e2e;
//font-sizes
$title-size: 2.4rem;
$accent-size: 1.6rem;

//font-family
$title-font: 'Roboto','sans-serif';
$accent-font: 'Roboto','sans-serif';

//sizes
$xs: 4px;
$sm: 8px;
$md: 16px;
$lg: 24px;
$xl: 32px;

//layouting
$wrapper-val:7.5vw;