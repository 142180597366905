@import "./layout.scss";

body {
  font-family: $title-font;
  color: $text-color;
}

.logo {
  font-size: 2.4rem;
  font-weight: bolder;
}

.logo-mini {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  color: white;
  font-size: 1.6rem;
}

.btn {
  padding: $sm $lg;
  border-radius: $xl;
  background: rgba(0, 0, 0, 0.12);
  border: none;
  color: $text-color;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  font-size: 1rem;

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
  }

  &.primary {
    background: $primary-color;
    color: $light-color;
  }

  &.secondary {
    background: $secondary-color;
    color: $light-color;
  }

  &.light {
    background: $light-color;
    color: $primary-color;
  }

  &.success {
    background: $success-color;
    color: $light-color;
  }

  &.danger {
    background: $danger-color;
    color: $light-color;
  }

  &.info {
    background: $info-color;
    color: $light-color;
  }

  &.warning {
    background: $warning-color;
    color: $light-color;
  }

  &.outlined.primary {
    border: 1px solid $primary-color;
    background: transparent;
    color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $light-color;
    }
  }

  &.outlined.secondary {
    border: 1px solid $secondary-color;
    background: transparent;
    color: $secondary-color;

    &:hover {
      background: $secondary-color;
      color: $light-color;
    }
  }

  &.outlined.light {
    border: 1px solid $light-color;
    background: transparent;
    color: $light-color;

    &:hover {
      background: $light-color;
      color: $light-color;
    }
  }

  &.outlined.success {
    border: 1px solid $success-color;
    background: transparent;
    color: $success-color;

    &:hover {
      background: $success-color;
      color: $light-color;
    }
  }

  &.outlined.danger {
    border: 1px solid $danger-color;
    background: transparent;
    color: $danger-color;

    &:hover {
      background: $danger-color;
      color: $light-color;
    }
  }

  &.outlined.info {
    border: 1px solid $info-color;
    background: transparent;
    color: $info-color;

    &:hover {
      background: $info-color;
      color: $light-color;
    }
  }

  &.outlined.warning {
    border: 1px solid $warning-color;
    background: transparent;
    color: $warning-color;

    &:hover {
      background: $warning-color;
      color: $light-color;
    }
  }
}

.form-group {
  margin-bottom: $md;
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.8rem;
  }

  input {
    background: rgba(0, 0, 0, 0.12);
    color: $text-color;
    border-radius: $xs;
    border: none;
    padding: $sm;
    &.rounded {
      border-radius: 30px;
      padding: $sm $lg;
    }
    &::placeholder {
      font-family: $title-font;
    }
  }

  .input-area {
    display: flex;
    align-items: center;
    input {
      flex: 1;
    }
  }

  .error-text {
    text-align: right;
    color: $danger-color;
  }
}

//backgrounds
.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-light {
  background-color: $light-color;
}

.bg-success {
  background-color: $success-color;
}

.bg-danger {
  background-color: $danger-color;
}

.bg-info {
  background-color: $info-color;
}

.bg-warning {
  background-color: $warning-color;
}

//texts
.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-light {
  color: $light-color;
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $danger-color;
}

.text-info {
  color: $info-color;
}

.text-warning {
  color: $warning-color;
}

.title {
  font-size: $title-size;
  font-weight: bold;
}

.sub-title {
  font-size: $accent-size;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.card,
%card {
  border-radius: $sm;
  padding: $md;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

//scrollbar
.pointer {
  cursor: pointer;
}
section {
  height: 100vh;
  background-color: #0c0c0c;
  width: 100vw;
  padding: 6vh 7.5vw;
}

.headline {
  font-family: "Bebas Neue";
  font-size: 88px;
  text-align: right;
  z-index: 1;
  color: rgb(94, 94, 94);
  line-height: 80px;
  margin-top: 48px;
}
.secondary-text {
  font-family: "Montserrat";
  color: rgb(94, 94, 94);
  z-index: 1;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}
.bg-el-home {
  height: 140px;
  width: 140px;
  border-radius: 100%;
  background: rgb(233, 233, 233);
  position: absolute;
  animation: moveAround linear infinite 28s;
  z-index: 0;
}
.bg-grid {
  position: absolute;
  mix-blend-mode: lighten;
  filter: brightness(0.2);
  img {
    width: 60%;
    height: 60%;
  }
}
@keyframes moveAround {
  from {
    transform: rotate(0deg) translateX(240px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(240px) rotate(-360deg);
  }
}

.active {
  border-bottom: 4px solid #fff;
}
span {
  transition: 0.4s all;
}
a {
  text-decoration: none;
  color: inherit;
}
.np {
  color: #eea23f;
  &:hover {
    color: white;
  }
  // color: white;
}
.prj {
  color: #7e3ba5;
}
.bkt {
  color: #c94848;
  &:hover {
    color: white;
  }
  // color: white;
}
.gf {
  color: #7ebd41;
  // color: white;
}
.socials {
  .fb {
    color: #0a66c2;
    &:hover {
      color: white;
    }
    // color: white;
  }
  .li {
    color: #0a66c2;
    &:hover {
      color: white;
    }
    // color: white;
  }
}

// @keyframes clipLeft {
//   from {
//     clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
//   }
//   to {
//     clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
//   }
// }

._12c4J {
  height: 100vh;
  width: 100vw;
}
* {
  user-select: none;
}

.nm8-1 {
  &::before {
    animation: clipLeft ease-in-out 1s infinite;
    animation-direction: alternate-reverse;
    transition: 0.4s all;
    content: "";
    height: 100vh;
    width: 1.3vw;
    background: #eea23f;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
.nm8-2 {
  &::before {
    transition: 0.4s all;
    animation: clipLeft ease-in-out 1s infinite;
    animation-direction: alternate-reverse;
    content: "";
    height: 100vh;
    width: 1.3vw;
    background: #c94848;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
.nm8-3 {
  &::before {
    transition: 0.4s all;
    animation: clipLeft ease-in-out 1s infinite;
    animation-direction: alternate-reverse;
    content: "";
    height: 100vh;
    width: 1.3vw;
    background: #7e3ba5;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
.nm8-4 {
  &::before {
    transition: 0.4s all;
    animation: clipLeft ease-in-out 1s infinite;
    animation-direction: alternate-reverse;
    content: "";
    height: 100vh;
    width: 1.3vw;
    background: #7ebd41;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
.nm8-5 {
  &::before {
    transition: 0.4s all;
    animation: clipLeft ease-in-out 1s infinite;
    animation-direction: alternate-reverse;
    content: "";
    height: 100vh;
    width: 1.3vw;
    background: #2d88ff;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}

.nm8-contents {
  animation: fade 0.5s linear;
}
@keyframes fade {
  0% {
    opacity: 0%;
    transform: translateY(-10px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  img {
    display: none;
  }
  .adjust-height {
    height: inherit;
    .headline {
      height: 25%;
    }
  }
  * {
    word-break: break-word;
  }
  .headline {
    font-size: 2.6rem;
    line-height: 1.1em;
  }
  .nav {
    width: 135px !important;
    font-size: 14px !important;
  }
  .container {
    padding: 0px 20px;
  }
  ul{
    padding: 0;
  }
  li {
    font-size: 16px;
  }
  .indicate-title {
    display: none;
  }
  .secondary-text {
    width: auto !important;
  }
  .particles {
    #tsparticles {
      height: 100vh !important;
      width: 100vw !important;
    }
  }

  .instruction {
    display: none;
  }
}

p {
  font-size: 18px;
}

.instruction {
  color: rgb(194, 194, 194);
  font-weight: 200;
  padding: 8px 24px;
  background: rgba(255, 255, 255, 0.123);
  border-radius: 40px;
  transition: 0.4s all;
  font-family: monospace;
  font-size: 17px;
}

li {
  margin-bottom: 12px;
  transition: 0.4s all;
  &:hover {
    color: white;
  }
}
