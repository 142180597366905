/* width */

::-webkit-scrollbar {
  display: none;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgb(255, 204, 65);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  overflow: hidden;
  cursor: none;
}

